import { CanonicalRole } from '@squareup/dex-types-shared-rbac-permissions';
import { getEnvironmentName } from '@squareup/dex-utils-environment';

const CANONICAL_ROLES_STAGING = new Map<string, string>([
  [CanonicalRole.API_LOGS_READ, 'GT1LGFOKF762'],
  [CanonicalRole.DEVELOPER_APPLICATION_APPLE_PAY_READ, 'AK7BAJG3NZNG'],
  [CanonicalRole.DEVELOPER_APPLICATION_APPLE_PAY_WRITE, 'R9B5VTC1L945'],
  [CanonicalRole.DEVELOPER_APPLICATION_CREDENTIALS_READ, '3H198D5G0AY7'],
  [CanonicalRole.DEVELOPER_APPLICATION_CREDENTIALS_WRITE, 'SOWHMT2LJRQN'],
  [CanonicalRole.DEVELOPER_APPLICATION_OAUTH_READ, 'UDTBA0EWG37F'],
  [CanonicalRole.DEVELOPER_APPLICATION_OAUTH_WRITE, 'LMXBCREIO667'],
  [CanonicalRole.DEVELOPER_APPLICATION_POINT_OF_SALE_API_READ, 'YP80738B3EN5'],
  [CanonicalRole.DEVELOPER_APPLICATION_POINT_OF_SALE_API_WRITE, '24G9XR3KE43T'],
  [CanonicalRole.DEVELOPER_APPLICATION_READ, 'XJH7V1MHDM4P'],
  [CanonicalRole.DEVELOPER_APPLICATION_WRITE, 'IFK1I9BSYBEX'],
  [CanonicalRole.DEVELOPER_APPLICATION_READER_SDK_READ, '0FF2TF4NPOE3'],
  [CanonicalRole.DEVELOPER_APPLICATION_READER_SDK_WRITE, 'YP38CV9DEVDM'],
  [CanonicalRole.DEVELOPER_APPLICATION_REVIEWS_READ, '08H94NOTHMJ8'],
  [CanonicalRole.DEVELOPER_APPLICATION_REVIEWS_WRITE, 'A7T0AZQCAD7P'],
  [CanonicalRole.DEVELOPER_APPLICATION_SUBMISSIONS_READ, '8W4QOJGPDNQB'],
  [CanonicalRole.DEVELOPER_APPLICATION_SUBMISSIONS_WRITE, 'EPCH445UJTMQ'],
  [CanonicalRole.DEVELOPER_APPLICATION_WEBHOOKS_READ, 'VNE8LM25JJ1Q'],
  [CanonicalRole.DEVELOPER_APPLICATION_WEBHOOKS_WRITE, '6VVBFFW22AYZ'],
  [CanonicalRole.DEVELOPER_COLLABORATOR_PERMISSIONS_READ, 'ML7JNILBWO9X'],
  [CanonicalRole.DEVELOPER_COLLABORATOR_PERMISSIONS_WRITE, 'L0DA3XIJ64YF'],
  [CanonicalRole.DEVELOPER_PROFILE_READ, 'SY06YM9J0TEX'],
  [CanonicalRole.DEVELOPER_PROFILE_WRITE, 'BOOHXVZ3W1MZ'],
  [CanonicalRole.DEVELOPER_SANDBOX_ACCOUNT_READ, 'YT6GKCBRAAN1'],
  [CanonicalRole.DEVELOPER_SANDBOX_ACCOUNT_WRITE, 'SWLQA3XCHZZX'],
  [CanonicalRole.MERCHANT_OWNER, '9V3ST51JQD71'],
  [CanonicalRole.MERCHANT_PROFILE_READ, 'RCLV35VBFGG6'],
  [CanonicalRole.MERCHANT_PROFILE_WRITE, 'KBZUTNDCJV52'],
  [CanonicalRole.EMPLOYEE_DASHBOARD_MANAGE_EMPLOYEES, 'Z0CCAXX590LK'],
  [CanonicalRole.DEVELOPER_APPLICATION_APP_SIGNATURES_READ, 'LH75LNHOALET'],
  [CanonicalRole.DEVELOPER_APPLICATION_APP_SIGNATURES_WRITE, '6OI2O9Y9XNO5'],
]);

const CANONICAL_ROLES_PRODUCTION = new Map<string, string>([
  [CanonicalRole.API_LOGS_READ, 'G51C3U3CWX0H'],
  [CanonicalRole.DEVELOPER_APPLICATION_APPLE_PAY_READ, '5258VK6IGFP1'],
  [CanonicalRole.DEVELOPER_APPLICATION_APPLE_PAY_WRITE, '1LBRQ2C8ARLQ'],
  [CanonicalRole.DEVELOPER_APPLICATION_CREDENTIALS_READ, '3PL1A7636O66'],
  [CanonicalRole.DEVELOPER_APPLICATION_CREDENTIALS_WRITE, 'UMUPF9439VVQ'],
  [CanonicalRole.DEVELOPER_APPLICATION_OAUTH_READ, 'LWP2QGVRFRST'],
  [CanonicalRole.DEVELOPER_APPLICATION_OAUTH_WRITE, '2GLOHSV79PW8'],
  [CanonicalRole.DEVELOPER_APPLICATION_POINT_OF_SALE_API_READ, 'PMDFKOWHSAYW'],
  [CanonicalRole.DEVELOPER_APPLICATION_POINT_OF_SALE_API_WRITE, '2SEE05X2V497'],
  [CanonicalRole.DEVELOPER_APPLICATION_READ, '75F0QJLR1USS'],
  [CanonicalRole.DEVELOPER_APPLICATION_WRITE, 'ONL89LUREB6Z'],
  [CanonicalRole.DEVELOPER_APPLICATION_READER_SDK_READ, 'VCEA05P0GMTQ'],
  [CanonicalRole.DEVELOPER_APPLICATION_READER_SDK_WRITE, '8MPDZIVBLF4Q'],
  [CanonicalRole.DEVELOPER_APPLICATION_REVIEWS_READ, 'GXD11CRK9CKX'],
  [CanonicalRole.DEVELOPER_APPLICATION_REVIEWS_WRITE, 'G6QOGJCGPVYZ'],
  [CanonicalRole.DEVELOPER_APPLICATION_SUBMISSIONS_READ, '5CIK4V2UOH1U'],
  [CanonicalRole.DEVELOPER_APPLICATION_SUBMISSIONS_WRITE, '22PF9KPS6LYQ'],
  [CanonicalRole.DEVELOPER_APPLICATION_WEBHOOKS_READ, 'ASB1XXKSHV36'],
  [CanonicalRole.DEVELOPER_APPLICATION_WEBHOOKS_WRITE, 'N60W5E06PT1H'],
  [CanonicalRole.DEVELOPER_COLLABORATOR_PERMISSIONS_READ, '5MLLVQ55SSLZ'],
  [CanonicalRole.DEVELOPER_COLLABORATOR_PERMISSIONS_WRITE, 'GFB3TI3OK6IZ'],
  [CanonicalRole.DEVELOPER_PROFILE_READ, '4SQQIYCIPMB5'],
  [CanonicalRole.DEVELOPER_PROFILE_WRITE, 'HBRHXYIGHQN1'],
  [CanonicalRole.DEVELOPER_SANDBOX_ACCOUNT_READ, '312Z4Y66ZR5F'],
  [CanonicalRole.DEVELOPER_SANDBOX_ACCOUNT_WRITE, '9RGR1MUD4T7D'],
  [CanonicalRole.MERCHANT_OWNER, 'GH2ZOZGVAWMA'],
  [CanonicalRole.MERCHANT_PROFILE_READ, 'O6XYNBCSX09L'],
  [CanonicalRole.MERCHANT_PROFILE_WRITE, 'RQ59U3OR2WGH'],
  [CanonicalRole.EMPLOYEE_DASHBOARD_MANAGE_EMPLOYEES, 'ILDSSYOPZ54A'],
  [CanonicalRole.DEVELOPER_APPLICATION_APP_SIGNATURES_READ, 'NLGHO7PVMDKT'],
  [CanonicalRole.DEVELOPER_APPLICATION_APP_SIGNATURES_WRITE, '4PB65O5UN6XZ'],
]);

const getCanonicalRoleTokens = () => {
  switch (getEnvironmentName()) {
    case 'development':
      return CANONICAL_ROLES_STAGING;
    case 'staging':
      return CANONICAL_ROLES_STAGING;
    case 'production':
      return CANONICAL_ROLES_PRODUCTION;
    default:
      throw new Error('Invalid environment.');
  }
};

export { getCanonicalRoleTokens };
