/* eslint-disable complexity */
import { ParsedUrlQuery } from 'querystring';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Graph } from '@square/ignition';
import {
  findGraph,
  extractGraphApi,
  extractGraphEndpoint,
  extractGraphWebhook,
  extractGraphObject,
  extractGraphEnum,
} from '@squareup/dex-data-access-oas';
import { extractFirstValue } from '@squareup/dex-tech-utils-routing';
import { SliceError, StoreStateNode } from '@squareup/dex-types-data-state';

type GraphEntitiesInView = {
  api?: string;
  endpoint?: string;
  object?: string;
  enum?: string;
  webhook?: string;
};
type GraphEntitiesInViewState = StoreStateNode<GraphEntitiesInView>;

const parseError: SliceError = {
  data: '',
  error: 'Error parsing value',
  originalStatus: 404,
  status: 'PARSING_ERROR',
};

const paramError: SliceError = {
  data: '',
  error: 'Error parsing params',
  originalStatus: 404,
  status: 'PARSING_ERROR',
};

type EntityConfig<TParsedValue = { [key: string]: string | undefined }> = {
  parsedValue: TParsedValue;
  name: keyof TParsedValue;
  extractor: (_value: TParsedValue, _graph: Graph) => unknown | undefined;
};

function extractEntitiesStateOrError(
  state: GraphEntitiesInViewState,
  entityConfigs: EntityConfig[],
  graph: Graph
): GraphEntitiesInViewState {
  if (entityConfigs.length === 0) {
    return state;
  }

  return entityConfigs.reduce(
    (
      reducedState: GraphEntitiesInViewState,
      { name, parsedValue, extractor }: EntityConfig
    ) => {
      // If the value exists then extract.
      if (parsedValue[name]) {
        const extractedValue = extractor(parsedValue, graph);
        if (!extractedValue) {
          // If the extractor found nothing but the config says it should exist that is an error scenario
          const error = reducedState.error;
          const notFoundMessage = `${name} not found in graph. error state:${JSON.stringify(
            error
          )} parsedValue:${JSON.stringify(parsedValue)}`;
          const stringifiedParsedValue = JSON.stringify(parsedValue);

          if (error) {
            const data = error.data
              ? `${error.data}, ${stringifiedParsedValue}`
              : stringifiedParsedValue;

            const errorMessage = error.error
              ? `${error.error}, ${notFoundMessage}`
              : notFoundMessage;

            return {
              error: {
                ...parseError,
                data,
                error: errorMessage,
              },
            };
          } else {
            return {
              error: {
                ...parseError,
                data: stringifiedParsedValue,
                error: notFoundMessage,
              },
            };
          }
        } else {
          return { ...reducedState, ...parsedValue };
        }
      }
      return reducedState;
    },
    { error: undefined, isLoading: false }
  );
}

const initialState: GraphEntitiesInViewState = { isLoading: true };

const graphEntitiesInViewSlice = createSlice({
  initialState,
  name: 'graphEntitiesInView',
  reducers: {
    /**
     * Responsible for taking the parsed query and inspecting the graph
     * for thier existence. If the query contains an entity and its not present an
     * error state will be reached. Otherwise the graph key/value for the entity will be stored.
     */
    setGraphEntitiesInView(
      state: GraphEntitiesInViewState,
      {
        payload: { query, version, apiExplorerQuery = false },
      }: PayloadAction<{
        query: ParsedUrlQuery;
        version: string;
        apiExplorerQuery?: boolean;
      }>
    ) {
      const {
        api: apiQuery,
        endpoint: endpointQuery,
        webhook: webhookQuery,
        object: objectQuery,
        enum: enumQuery,
      } = query;
      const hasQueryValue =
        apiQuery || endpointQuery || webhookQuery || objectQuery || enumQuery;
      if (!hasQueryValue) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return { isLoading: false } as any;
      }

      const api = extractFirstValue(apiQuery);
      const endpoint = extractFirstValue(endpointQuery);
      const webhook = extractFirstValue(webhookQuery);
      const object = extractFirstValue(objectQuery);
      const enumValue = extractFirstValue(enumQuery);

      const noChangeInState =
        api === state.api &&
        endpoint === state.endpoint &&
        webhook === state.webhook &&
        object === state.object &&
        enumValue === state.enum;

      // Even if there is no state change allow for clearing of errors.
      if (noChangeInState && !state.error) {
        return state;
      }

      const noEntityResolved =
        !api && !endpoint && !webhook && !object && !enumValue;
      if (noEntityResolved) {
        return {
          error: {
            ...parseError,
            data: JSON.stringify(query) || '',
            error:
              'Unable to resolve the path in view to one or more entities in query.',
          },
        };
      } else {
        const graph = findGraph(version);

        if (!graph) {
          return {
            error: {
              ...parseError,
              data: version,
              error: 'Unable to find graph to resolve query.',
            },
          };
        } else {
          if (apiExplorerQuery) {
            // 1. Ensure that any query with `api` has an endpoint
            // 2. Ensure any query with an array of endpoints 404s

            if (api && !endpoint) {
              return {
                error: {
                  ...paramError,
                  error: 'API Explorer queries must have an endpoint',
                },
              };
            } else if (
              Array.isArray(endpointQuery) &&
              endpointQuery.length > 1
            ) {
              return {
                error: {
                  ...paramError,
                  error:
                    'API Explorer queries must have a single endpoint param',
                },
              };
            }
          }

          const entitiesToExtract = [
            {
              extractor: extractGraphApi,
              name: 'api',
              parsedValue: { api },
            },
            {
              extractor: extractGraphEndpoint,
              name: 'endpoint',
              parsedValue: { endpoint },
            },
            {
              extractor: extractGraphWebhook,
              name: 'webhook',
              parsedValue: { webhook },
            },
            {
              extractor: extractGraphObject,
              name: 'object',
              parsedValue: { object },
            },
            {
              extractor: extractGraphEnum,
              name: 'enum',
              parsedValue: { enum: enumValue },
            },
          ];

          return extractEntitiesStateOrError(state, entitiesToExtract, graph);
        }
      }
    },
    setGraphEntitiesInViewLoading(
      state: GraphEntitiesInViewState,
      { payload: { isLoading } }: PayloadAction<{ isLoading: boolean }>
    ) {
      state.isLoading = isLoading;
    },
  },
});

// Actions
const { setGraphEntitiesInView, setGraphEntitiesInViewLoading } =
  graphEntitiesInViewSlice.actions;

// Store config
const graphEntitiesInViewStoreConfig = {
  reducer: graphEntitiesInViewSlice.reducer,
  reducerPath: graphEntitiesInViewSlice.name,
};

export {
  setGraphEntitiesInView,
  graphEntitiesInViewSlice,
  graphEntitiesInViewStoreConfig,
  setGraphEntitiesInViewLoading,
};
