interface MarkdownBlock {
  type: 'markdown';
  name: string;
  markdown: string;
  programmingLanguage: string;
  platform: string;
}

interface MultiLanguageBlock {
  type: 'multi-language';
  blocks: Array<MarkdownBlock>;
}
interface NavBlockPreviousNextSteps {
  type: 'next-steps';
  previousBlock?: {
    title: string;
    url: string;
  };
  nextBlock?: {
    title: string;
    url: string;
  };
}

type ContentBlock =
  | MultiLanguageBlock
  | NavBlockPreviousNextSteps
  | MarkdownBlock;

interface DocPage {
  type: 'doc-page';
  id: string;
  slug: string;
  searchSummary: string;
  heading: {
    title: string;
    browserTitle: string;
    releaseStatus:
      | 'PUBLIC'
      | 'BETA'
      | 'DEPRECATED'
      | 'DEPRECATED and RESTRICTED'
      | 'NEW'
      | 'CAPABILITY';
    eyebrow?: string;
    summary?: string;
  };
  languagesSupported?: string[];
  platforms?: string[];
  leftNavId?: string | undefined;
  content: Array<ContentBlock>;
}

interface ChangelogEntryPage {
  type: 'changelog-entry-page';
  id: string;
  slug: string;
  linkBack: string;
  changelogDate: string;
  summary: string;
  tags: string[];
  details: string;
  leftNavId?: string;
  versions?: {
    square: string;
    java?: string;
    dotnet?: string;
    nodejs?: string;
    php?: string;
    python?: string;
    ruby?: string;
    go?: string;
  };
}

interface ChangelogSetPage {
  type: 'changelog-set-page';
  id: string;
  slug: string;
  heading: {
    title: string;
    browserTitle: string;
    releaseStatus: string;
    eyebrow: string;
    summary?: string;
  };
  leftNavId: string;
  latestChangelog: ChangelogEntryPage;
  changelogHistory: Array<ChangelogEntryPage>;
}

interface DocRedirect {
  type: 'redirect';
  slug: string;
  redirectTo: string;
}

interface ReleaseTrain {
  slug: string;
  title: string;
  released: boolean;
  routes: Array<string>;
  navigationItems: Array<{ id: string }>;
}

type DocRoute = Pick<
  DocPage | ChangelogEntryPage | ChangelogSetPage | DocRedirect,
  'slug' | 'type'
>;

enum DocType {
  DocPage = 'doc-page',
  NavSet = 'nav-set',
}

export type {
  ChangelogSetPage,
  ChangelogEntryPage,
  DocPage,
  ContentBlock,
  MultiLanguageBlock,
  NavBlockPreviousNextSteps,
  MarkdownBlock,
  DocRedirect,
  ReleaseTrain,
  DocRoute,
};

export { DocType };
