import { hasCookie, removeCookie } from '@square/onetrust-compliant-access';

const apiExplorerMigrationCookie = 'api-explorer-dex-tech-fe-migration';

const hasApiExplorerMigrationCookie = () => {
  // The value doesn't matter. Just existence.
  return hasCookie(apiExplorerMigrationCookie);
};

const removeApiExplorerMigrationCookie = () => {
  removeCookie(apiExplorerMigrationCookie);
};

export { hasApiExplorerMigrationCookie, removeApiExplorerMigrationCookie };
