import { ChangelogEntryPage } from '@squareup/dex-types-shared-docs';
import { codeLanguageDisplayMap } from '@squareup/dex-utils-oas';

type SdkLanguage = Exclude<
  keyof NonNullable<ChangelogEntryPage['versions']>,
  'square'
>;

function getMajorVersion(sdkVersion = '0'): number {
  const version = sdkVersion.startsWith('v') ? sdkVersion.slice(1) : sdkVersion;
  return Number.parseInt(version?.split('.')?.[0] || '0', 10);
}

function getRepoForSdk(version: string, lang: SdkLanguage): string | null {
  const majorVersion = getMajorVersion(version);

  switch (lang) {
    case 'dotnet':
      return majorVersion > 2 ? 'square-dotnet-sdk' : 'connect-csharp-sdk';
    case 'java':
      return majorVersion > 2 ? 'square-java-sdk' : 'connect-java-sdk';
    case 'nodejs':
      return majorVersion > 6 ? 'square-nodejs-sdk' : 'connect-nodejs-sdk';
    case 'php':
      return majorVersion > 3 ? 'square-php-sdk' : 'connect-php-sdk';
    case 'python':
      return majorVersion > 2 ? 'square-python-sdk' : 'connect-python-sdk';
    case 'ruby':
      return majorVersion > 2 ? 'square-ruby-sdk' : 'connect-ruby-sdk';
    case 'go':
      return 'square-go-sdk';
    default:
      return null;
  }
}

function toGithubUrl(version: string, lang: SdkLanguage): string | null {
  const repo = getRepoForSdk(version, lang);
  if (!repo) {
    return null;
  }

  return `https://github.com/square/${repo}/releases/tag/${version}`;
}

function toSdkTitle(lang: SdkLanguage): string {
  if (lang === 'dotnet') {
    return '.NET';
  }

  const codeExampleLang = lang === 'nodejs' ? 'javascript' : lang;

  const display = codeLanguageDisplayMap.get(codeExampleLang);
  return display || lang;
}

export { toGithubUrl, toSdkTitle, type SdkLanguage };
