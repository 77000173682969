import { NullableClassName, OnClickProp } from '@squareup/dex-types-shared-ui';
import { TrackedProps } from '@squareup/dex-types-shared-utils';
import { Box, BoxProps } from '@squareup/dex-ui-shared-base';
import { MarketTooltip } from '@squareup/dex-ui-shared-market';
import clsx from 'clsx';
import React, {
  AriaAttributes,
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
} from 'react';

import { NativeButton } from '../NativeButton';

import styles from './icon-button.module.css';

interface IconButtonProps {
  icon: ReactNode;
  tooltipText?: string;
  noHoverStyling?: boolean;
  popoverPlacement?:
    | typeof globalThis.HTMLMarketTooltipElement.prototype['popoverPlacement']
    | undefined;
  width?: number;
  height?: number;
  containerClassName?: string | undefined;
  colorVariant?: '10' | '20' | '30';
}

const IconButton: FunctionComponent<
  IconButtonProps &
    BoxProps<HTMLButtonElement> &
    NullableClassName &
    OnClickProp &
    TrackedProps &
    AriaAttributes
> = ({
  icon,
  onClick,
  className,
  containerClassName,
  tooltipText,
  trackingId,
  trackingExtra,
  noHoverStyling,
  popoverPlacement = 'top',
  width = 32,
  height = 36,
  colorVariant,
  ...rest
}) => {
  const [isServer, setIsServer] = useState(true);

  useEffect(() => {
    // Only load in MarketTooltip in client side
    setIsServer(false);
  }, []);

  const iconButtonTrigger = (
    <NativeButton
      trackingId={trackingId}
      trackingExtra={trackingExtra}
      slot="trigger"
      className={clsx(
        styles['icon'],
        !noHoverStyling && styles['hover-styling'],
        colorVariant && styles[`color-${colorVariant}`],
        className
      )}
      onClick={onClick}
      {...rest}
    >
      {icon}
    </NativeButton>
  );

  return (
    // It seems like the MarketTooltip className attribute only takes effect
    // well after the component loads in. So the initial render flashes without
    // our CSS in the 'dropdown' class. Putting it in a Box ensures it loads in right away
    <Box
      className={clsx(styles['dropdown'], containerClassName)}
      style={
        {
          '--tooltip-min-width': `${width}px`,
          '--tooltip-min-height': `${height}px`,
        } as React.CSSProperties
      }
    >
      {isServer || !tooltipText ? (
        iconButtonTrigger
      ) : (
        <MarketTooltip popoverPlacement={popoverPlacement}>
          {iconButtonTrigger}
          <span slot="content">{tooltipText}</span>
        </MarketTooltip>
      )}
    </Box>
  );
};

export { IconButton };
