import {
  registerLanguage,
  registerAliases,
  highlight as highlightCore,
} from 'highlight.js/lib/core';
import bash from 'highlight.js/lib/languages/bash';
import csharp from 'highlight.js/lib/languages/csharp';
import go from 'highlight.js/lib/languages/go';
import gradle from 'highlight.js/lib/languages/gradle';
import java from 'highlight.js/lib/languages/java';
import javascript from 'highlight.js/lib/languages/javascript';
import json from 'highlight.js/lib/languages/json';
import kotlin from 'highlight.js/lib/languages/kotlin';
import markdown from 'highlight.js/lib/languages/markdown';
import objectivec from 'highlight.js/lib/languages/objectivec';
import php from 'highlight.js/lib/languages/php';
import python from 'highlight.js/lib/languages/python';
import ruby from 'highlight.js/lib/languages/ruby';
import swift from 'highlight.js/lib/languages/swift';
import xml from 'highlight.js/lib/languages/xml';
import parse from 'html-react-parser';
import { ReactNode } from 'react';

export type HighlightLanguage =
  | 'curl'
  | 'bash'
  | 'json'
  | 'ruby'
  | 'python'
  | 'java'
  | 'csharp'
  | 'go'
  | 'php'
  | 'javascript'
  | 'nodejs'
  | 'xml'
  | 'html'
  | 'objectivec'
  | 'swift'
  | 'kotlin'
  | 'gradle'
  | 'markdown'
  | 'go';

interface LanguageRegistration {
  type: 'language';
  lang: HighlightLanguage;
  module: unknown;
}

interface AliasRegistration {
  type: 'alias';
  lang: HighlightLanguage;
  alias: { languageName: HighlightLanguage };
}

const registeredLanguages: Array<LanguageRegistration | AliasRegistration> = [
  {
    type: 'language',
    lang: 'bash',
    module: bash,
  },
  {
    type: 'alias',
    lang: 'curl',
    alias: {
      languageName: 'bash',
    },
  },
  {
    type: 'language',
    lang: 'json',
    module: json,
  },
  {
    type: 'language',
    lang: 'javascript',
    module: javascript,
  },
  {
    type: 'language',
    lang: 'csharp',
    module: csharp,
  },
  {
    type: 'language',
    lang: 'go',
    module: go,
  },
  {
    type: 'alias',
    lang: 'nodejs',
    alias: {
      languageName: 'javascript',
    },
  },
  {
    type: 'language',
    lang: 'ruby',
    module: ruby,
  },
  {
    type: 'language',
    lang: 'python',
    module: python,
  },
  {
    type: 'language',
    lang: 'java',
    module: java,
  },
  {
    type: 'language',
    lang: 'php',
    module: php,
  },
  {
    type: 'language',
    lang: 'swift',
    module: swift,
  },
  {
    type: 'language',
    lang: 'objectivec',
    module: objectivec,
  },
  {
    type: 'language',
    lang: 'xml',
    module: xml,
  },
  {
    type: 'language',
    lang: 'html',
    module: xml,
  },
  {
    type: 'language',
    lang: 'kotlin',
    module: kotlin,
  },
  {
    type: 'language',
    lang: 'gradle',
    module: gradle,
  },
  {
    type: 'language',
    lang: 'markdown',
    module: markdown,
  },
  {
    type: 'language',
    lang: 'go',
    module: go,
  },
];

const langset = new Set();
for (const reg of registeredLanguages) {
  langset.add(reg.lang);
  if (reg.type === 'language') {
    registerLanguage(reg.lang, reg.module);
  } else {
    registerAliases(reg.lang, reg.alias);
  }
}

const highlight = (
  code: string | undefined,
  language: HighlightLanguage
): ReactNode => {
  if (!code) {
    return '';
  }

  const highlightedCode = langset.has(language)
    ? highlightCore(code, { language }).value
    : code;

  const lineTaggedCode = highlightedCode
    .split('\n')
    .map((code: string) => {
      return `<span data-line-number></span>${code}`;
    })
    .join('\n');
  return parse(lineTaggedCode);
};

export { highlight };
