import { Spinner } from '@squareup/dex-icons/dex/misc';
import { NullableClassName } from '@squareup/dex-types-shared-ui';
import { Box, BoxProps } from '@squareup/dex-ui-shared-base';
import { commonIconStyles } from '@squareup/dex-ui-shared-icon-styles';
import { HighlightLanguage } from '@squareup/dex-utils-highlight';
import clsx from 'clsx';
import React, {
  FunctionComponent,
  KeyboardEventHandler,
  MouseEventHandler,
  ReactNode,
  useCallback,
  useRef,
} from 'react';

import { themeStyles } from '../ThemeSwitcher';

import { Code } from './Code';
import styles from './code-block.module.css';

interface CodeBlockProps {
  title?: ReactNode | undefined;
  actionButtonGroup?: ReactNode | ReactNode[];
  footerButtonGroup?: ReactNode;
  code: string;
  language: HighlightLanguage;
  showLineNumbers?: boolean | undefined;
  isRequest?: boolean | undefined;
  // The header will appear to float inside the code block
  isFloatingHeader?: boolean | undefined;
  // If true, the text will wrap with the width of the code block
  wrap?: boolean;
  // Classname attribute for pre block
  preClassName?: string | undefined;
  isLoading?: boolean | undefined;
  headerBoxProps?: Pick<BoxProps, 'margin' | 'padding' | 'border'> | undefined;
  addCommentTypingAnimation?: boolean | undefined;
  fillParentHeight?: boolean | undefined;
}

const CodeBlock: FunctionComponent<
  CodeBlockProps &
    NullableClassName &
    Pick<BoxProps, 'margin' | 'padding' | 'border'>
> = ({
  title,
  actionButtonGroup,
  footerButtonGroup,
  code,
  showLineNumbers,
  className,
  isRequest,
  language,
  wrap = false,
  preClassName,
  isFloatingHeader = false,
  isLoading = false,
  headerBoxProps,
  addCommentTypingAnimation = false,
  fillParentHeight = false,
  ...boxProps
}) => {
  const preRef = useRef<HTMLElement>(null);
  const selectPreEl = useCallback(() => {
    if (!preRef.current) {
      return;
    }

    const selection = window.getSelection();
    const range = new Range();

    range.selectNodeContents(preRef.current);

    selection?.removeAllRanges();
    selection?.addRange(range);
  }, [preRef]);

  const onKeyDown = useCallback<KeyboardEventHandler<HTMLElement>>(
    (e) => {
      // User wants to select all
      if (e.metaKey && e.key === 'a') {
        e.preventDefault();
        selectPreEl();
      }
    },
    [selectPreEl]
  );

  const onClick = useCallback<MouseEventHandler<HTMLElement>>(
    (e) => {
      // Triple click selects the whole code block
      if (e.detail === 3) {
        e.preventDefault();
        selectPreEl();
      }
    },
    [selectPreEl]
  );

  const showHeader = actionButtonGroup || title;

  const standardHeader = (
    <Box
      // Ensures we can get keyboard events, but that the element isn't focusable
      tabIndex={-1}
      className={clsx(
        styles['header'],
        isRequest && styles.request,
        isRequest && themeStyles['dark-mode-theme']
      )}
      padding={{ vertical: '1x', left: '2x', right: '1x' }}
      border={{
        line: 'standard',
        radius: { topLeft: 'standard', topRight: 'standard' },
      }}
      {...headerBoxProps}
      onKeyDown={onKeyDown}
    >
      <Box>{title}</Box>
      <Box>{actionButtonGroup}</Box>
    </Box>
  );

  const floatingHeader = (
    <Box
      className={clsx(
        styles['header'],
        styles.floating,
        isRequest && styles.request,
        isRequest && themeStyles['dark-mode-theme']
      )}
      {...headerBoxProps}
    >
      <Box>{title}</Box>
      <Box margin={{ top: '5x', right: '1x' }}>{actionButtonGroup}</Box>
    </Box>
  );

  return (
    <>
      {
        // We place the standard header outside the code block, so that the actions and
        // dropdowns aren't cut off by the code block height
        showHeader && (isFloatingHeader ? floatingHeader : standardHeader)
      }
      <Box
        className={clsx(
          styles['code-block'],
          isRequest && styles.request,
          isRequest && themeStyles['dark-mode-theme'],
          addCommentTypingAnimation && styles['comment-typing-animation'],
          fillParentHeight && styles['fill-height'],
          className
        )}
        border={{
          line: showHeader
            ? { left: 'standard', right: 'standard', bottom: 'standard' }
            : 'standard',
          radius:
            showHeader && isFloatingHeader
              ? 'standard'
              : {
                  bottomLeft: 'standard',
                  bottomRight: 'standard',
                },
        }}
        data-language={language}
        data-testid="code-block"
        {...boxProps}
      >
        <Box
          as={'pre'}
          ref={preRef}
          // Ensures we can get keyboard events, but that the element isn't focusable
          tabIndex={-1}
          className={clsx(
            styles['main'],
            wrap && styles.wrap,
            isLoading && styles.loading,
            preClassName
          )}
          padding={{ vertical: '2x', horizontal: '2x' }}
          onKeyDown={onKeyDown}
          onClick={onClick}
        >
          {isLoading ? (
            <Spinner className={commonIconStyles['icon-spinner']} />
          ) : (
            <Code
              code={code}
              language={language}
              showLineNumbers={showLineNumbers}
            ></Code>
          )}
        </Box>
        {footerButtonGroup}
      </Box>
    </>
  );
};

export { CodeBlock };
