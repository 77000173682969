import {
  useHtmlHighlight,
  useShowMarkdown,
} from '@squareup/dex-store-access-dex-tech-docs-selectors';
import { ChangelogEntryPage, NavSet } from '@squareup/dex-types-shared-docs';
import { AnchoredHeading, DocBadge, Head } from '@squareup/dex-ui';
import { DocsPageContentLayout } from '@squareup/dex-ui-dex-tech-docs-page-layout';
import { Markdown } from '@squareup/dex-ui-markdown';
import { Box, Heading30 } from '@squareup/dex-ui-shared-base';
import {
  makeDocUrlRelative,
  useLeftNavItemsForPage,
} from '@squareup/dex-utils-docs-navigation';
import { getNextPublicDocsNameOrDefault } from '@squareup/dex-utils-environment';
import { toMarkdownHeadingHrefHash } from '@squareup/dex-utils-shared-markdown-helpers';
import React, { FC, useMemo } from 'react';

import { DocFeedbackPrompt } from '../DocFeedbackPrompt';
import { DocPageHeading } from '../DocPageHeading';
import { homeBreadcrumb } from '../markdown-block-hooks';

import {
  SdkLanguage,
  toGithubUrl,
  toSdkTitle,
} from './changelog-entry-page-utils';
import styles from './changelog-entry-page.module.css';

interface ChangelogEntryPageContentProps {
  page: ChangelogEntryPage;
  navSet?: NavSet | undefined;
}

const versionHeadingTitle = 'Version summary';
const detailsHeadingTitle = 'Details';

const ChangelogEntryPageContent: FC<ChangelogEntryPageContentProps> = ({
  page,
  navSet,
}) => {
  const showMarkdown = useShowMarkdown();
  const highlightHtml = useHtmlHighlight();

  const navItem = useLeftNavItemsForPage(page.leftNavId, navSet);
  const parentNavItem = useMemo(() => {
    return navItem?.items.find(
      (item) =>
        (item.type === 'subcategory' || item.type === 'link') &&
        makeDocUrlRelative(item.url) === makeDocUrlRelative(page.linkBack)
    );
  }, [navItem?.items, page.linkBack]);

  const breadcrumbs = useMemo(() => {
    if (
      parentNavItem &&
      (parentNavItem.type === 'subcategory' ||
        (parentNavItem.type === 'link' && parentNavItem.url))
    ) {
      return [
        homeBreadcrumb,
        {
          title: parentNavItem.title,
          href: makeDocUrlRelative(parentNavItem.url),
        },
      ];
    }

    return [];
  }, [parentNavItem]);

  const versionString =
    page.versions &&
    Object.entries(page.versions)
      .map(([sdk, version]) => {
        if (sdk === 'square') {
          return `- **Square Version**: \`${version}\`\n`;
        }

        const lang = sdk as SdkLanguage;
        const githubUrl = toGithubUrl(version, lang);
        const versionLabel = githubUrl
          ? `[\`${version}\`](${githubUrl})`
          : version;

        return `- **${toSdkTitle(lang)}**: ${versionLabel}\n`;
      })
      .join('');

  const pageTitle = `${page.changelogDate?.split('T')[0]} Changelog`;

  const browserTitle =
    (parentNavItem?.type !== 'divider' &&
      parentNavItem?.title &&
      `${parentNavItem.title}: ${pageTitle}`) ||
    `${getNextPublicDocsNameOrDefault()} - ${pageTitle}`;

  return (
    <DocsPageContentLayout feedbackPrompt={<DocFeedbackPrompt />}>
      <Head
        title={browserTitle}
        description={page?.summary || ''}
        canonicalPath={`/docs/${page.slug}`}
      />
      <DocPageHeading
        heading={{
          title: pageTitle,
        }}
        breadcrumbItems={breadcrumbs}
      />

      <Box
        className={styles['badge-list']}
        testId="changelog-entry-page-badge-list"
      >
        {page.tags.map((tag) => {
          return (
            <DocBadge key={tag} trackingId={'changelog-entry-badge'}>
              {tag}
            </DocBadge>
          );
        })}
      </Box>

      {page.versions && versionString && (
        <>
          <AnchoredHeading
            trackingId={'changelog-entry-versions-anchor'}
            anchor={toMarkdownHeadingHrefHash(versionHeadingTitle)}
            alignment="baseline"
          >
            <Heading30 margin={{ top: '5x' }}>{versionHeadingTitle}</Heading30>
          </AnchoredHeading>
          <Markdown markdown={versionString} />
        </>
      )}

      {page.details && (
        <>
          <AnchoredHeading
            anchor={toMarkdownHeadingHrefHash(detailsHeadingTitle)}
            trackingId={'changelog-entry-details-anchor'}
            alignment="baseline"
          >
            <Heading30 margin={{ top: '5x' }}>{detailsHeadingTitle}</Heading30>
          </AnchoredHeading>
          <Box>
            {showMarkdown ? (
              <Box
                margin={{ vertical: '2x' }}
                as="pre"
                className={styles['show-markdown-pre']}
              >
                {page.details}
              </Box>
            ) : (
              <Markdown markdown={page.details} highlightHtml={highlightHtml} />
            )}
          </Box>
        </>
      )}
    </DocsPageContentLayout>
  );
};

export { ChangelogEntryPageContent };
