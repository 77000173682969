import SqLogoWithText from '@squareup/dex-icons/dex/misc/SqLogoWithText';
import { Markdown } from '@squareup/dex-ui-markdown';
import {
  Box,
  Display10,
  Heading20,
  Paragraph20,
} from '@squareup/dex-ui-shared-base';
import { useDecision } from '@squareup/dex-ui-shared-feature-detection';
import { isDevelopmentEnvironment } from '@squareup/dex-utils-environment';
import React, { FC } from 'react';

import { error500, errorToContentMap, ErrorDetails } from './error-contents';
import styles from './error-page.module.css';

interface ErrorPageProps {
  code: number;
  showLogo?: boolean;
  errorMessage?: string;
}

const defaultError = error500;

const ErrorPage: FC<ErrorPageProps> = ({
  code,
  showLogo = false,
  errorMessage,
}) => {
  const { decision } = useDecision('error-details');
  const isErrorMessageDetailsEnabled =
    errorMessage && (isDevelopmentEnvironment() || decision.enabled);

  let details = defaultError;
  const roundedCode = Math.floor(code / 100) * 100;
  let displayedCode = code;
  if (errorToContentMap.has(code)) {
    details = errorToContentMap.get(code) as ErrorDetails;
  } else if (errorToContentMap.has(roundedCode)) {
    details = errorToContentMap.get(roundedCode) as ErrorDetails;
    displayedCode = roundedCode;
  }

  return (
    <Box className={styles.container}>
      {showLogo && (
        <Box margin={{ bottom: '5x' }}>
          <SqLogoWithText />
        </Box>
      )}

      <Display10
        margin={{ bottom: '2x', top: showLogo ? undefined : '4x' }}
        testId="error-heading"
      >
        <Display10 as="span" colorVariant="20">
          {displayedCode}
        </Display10>{' '}
        {details?.title}
      </Display10>
      <Box className={styles.content} testId="error-contents">
        <Markdown markdown={details.content} />
      </Box>
      {isErrorMessageDetailsEnabled && (
        <>
          <Heading20 margin={{ top: '2x' }}>Error details</Heading20>
          <Paragraph20
            as={'pre'}
            className={styles.error}
            testId="error-message"
          >
            {errorMessage}
          </Paragraph20>
        </>
      )}
    </Box>
  );
};

export { ErrorPage };
