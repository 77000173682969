enum PermissionSetDomainAction {
  VIEW_APPLICATIONS = 'View applications',
  UPDATE_APPLICATIONS = 'Update applications',
  VIEW_APPLICATION_CREDENTIALS = 'View application credentials',
  UPDATE_APPLICATION_CREDENTIALS = 'Update application credentials',
  VIEW_API_LOGS = 'View API logs',
  VIEW_SANDBOX_ENVIRONMENT_DETAILS = 'View sandbox environment details',
  UPDATE_SANDBOX_ENVIRONMENT_DETAILS = 'Update sandbox environment details',
  UPDATE_APP_MARKETPLACE_REVIEWS = 'Update App Marketplace reviews',
  VIEW_APP_MARKETPLACE_SUBMISSIONS = 'View App Marketplace submissions',
  UPDATE_APP_MARKETPLACE_SUBMISSIONS = 'Update App Marketplace submissions',
  UPDATE_DEVELOPER_ACCOUNT_SETTINGS = 'Update developer account settings',
  UPDATE_DEVELOPER_ACCOUNT_PERMISSIONS = 'Update developer account permissions',
  UPDATE_APPLICATION_OAUTH = 'Update application OAuth',
  MANAGE_EMPLOYEES = 'Manage employees',
  UPDATE_APPLICATION_SIGNATURES = 'Update application mobile payment SDK signatures',
}

export { PermissionSetDomainAction };
