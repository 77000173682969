import {
  BaseEndpointBuilder,
  endpointBuilder,
} from '@squareup/dex-data-shared-base-api';
import { DocPage, NavSet } from '@squareup/dex-types-shared-docs';
import { getNextPublicDeveloperCDNURL } from '@squareup/dex-utils-environment';
import { SchemaType } from '@squareup/dex-utils-shared-api-explorer';

import { DEVELOPER_API_REDUCER_PATH } from './developer-api-constants';

const DEX_TECH_FE_API_URL = '/api/dex-tech-fe';
const appName = 'dex-tech-fe';

const dexTechFeEndpointFactory = (
  builder: BaseEndpointBuilder<typeof DEVELOPER_API_REDUCER_PATH>
) => ({
  getDevGuidePage: builder.query<DocPage, { guide: string }>({
    query: ({ guide }) => {
      const path = `${DEX_TECH_FE_API_URL}/dev/guides/${guide}`;
      return {
        path,
        metricsHandlerName: `${DEX_TECH_FE_API_URL}/dev/guides/{guide}`,
        appName,
      };
    },
  }),
  getDevGuideNavSet: builder.query<NavSet, { namespace: string }>({
    query: ({ namespace }) => {
      const path = `${DEX_TECH_FE_API_URL}/dev/guides/${namespace}/nav-set`;
      return {
        path,
        metricsHandlerName: `${DEX_TECH_FE_API_URL}/dev/guides/${namespace}/nav-set`,
        appName,
      };
    },
  }),
  listDevGuidePages: builder.query<DocPage[], { namespace: string }>({
    query: ({ namespace }) => {
      const path = `${DEX_TECH_FE_API_URL}/dev/guides/${namespace}/all`;
      return {
        path,
        metricsHandlerName: `${DEX_TECH_FE_API_URL}/dev/guides/${namespace}/all`,
        appName,
      };
    },
  }),
  customAttributeSchema: builder.query<SchemaType, void>({
    query: () => ({
      baseUrlOverride: getNextPublicDeveloperCDNURL(),
      path: '/schemas/v1/common.json',
      ignoreCredentials: true,
      appName,
    }),
  }),
});

const dexTechFeEndpoints = dexTechFeEndpointFactory(endpointBuilder);

export { dexTechFeEndpoints, dexTechFeEndpointFactory };
