/* eslint-disable no-param-reassign */
import {
  parse as markdocParse,
  transform as markdocTransform,
  renderers as markdocRenderers,
  Node,
  RenderableTreeNode,
  Schema,
  NodeType,
  Tokenizer,
  Tag,
} from '@markdoc/markdoc';
import { useMarkdownContext } from '@squareup/dex-markdown-context-provider';
import {
  MarkdownNode,
  MarkdownComponent,
  MarkdownReactComponent,
  MarkdownSyntaxTransformer,
} from '@squareup/dex-types-shared-markdown';
import { TestProps } from '@squareup/dex-types-shared-utils';
import {
  ColorVariants,
  TextVariants,
  TextWeight,
} from '@squareup/dex-ui-shared-base';
import { doc } from '@squareup/dex-ui-shared-markdown-components';
import { bypassCodeBlocks, restoreCodeBlocks } from '@squareup/dex-utils-text';
import React, { ReactNode, FC } from 'react';

import { components as builtInComponents } from './components';
import { nodes as builtInNodes } from './nodes';
import { processTokens } from './parsers';
import { syntaxTransformers } from './syntax-transformers';

interface MarkdownOptions {
  highlightHtml?: boolean | undefined;
  disableAllComponents?: boolean | undefined;
  testId?: string | undefined;
  textColor?: ColorVariants['colorVariant'] | undefined;
  textVariant?: TextVariants['variant'] | undefined;
  textWeight?: TextWeight | undefined;
  extraComponents?: Array<MarkdownComponent> | undefined;
  extraNodes?: Array<MarkdownNode> | undefined;
}

/**
 * All custom components are initialized here, including nodes
 * (for default types like heading, fence, paragraph, etc) and
 * tags (full custom components). The app won't configure the extensions, but
 * rather they will be built-in to this library, to have a uniform library across
 * all apps.
 */
const customNodes: Array<MarkdownNode> = [...builtInNodes];
const customComponents: Array<MarkdownComponent> = [...builtInComponents];
const customSyntaxTransfomers: Array<MarkdownSyntaxTransformer> = [
  ...syntaxTransformers,
];

const markdocTokenizer = new Tokenizer({
  allowComments: true,
  allowIndentation: true,
});

function parse(
  markdown: string,
  { highlightHtml, disableAllComponents }: MarkdownOptions = {}
): Node {
  let finalMarkdown = markdown;

  if (!disableAllComponents) {
    // bypass code blocks
    const { result, blocks } = bypassCodeBlocks(finalMarkdown);
    finalMarkdown = result;

    // run all transforms
    for (const syntaxTransformer of customSyntaxTransfomers) {
      finalMarkdown = syntaxTransformer.transform(finalMarkdown, highlightHtml);
    }

    // restore code blocks
    const restored = restoreCodeBlocks(finalMarkdown, blocks);
    finalMarkdown = restored;
  }

  const tokens = markdocTokenizer.tokenize(finalMarkdown);

  return markdocParse(processTokens(tokens, markdocTokenizer), { slots: true });
}

/**
 * Process the AST with some non-markdoc transformations
 * The reason we have this is to workaround some concepts built into
 * the commonmark spec and some markdoc choices. Specifically, when you have
 * a tag within a tag.
 * Imagine this:
 * ```
 * {% layout %}
 *  {% card-badge %}
 *    Hello
 *  {% /card-badge %}
 *  {% card-badge %}
 *    Hello
 *  {% /card-badge %}
 * {% /layout %}
 * ```
 *
 * The problem here is that, by adding a newline in the tag, it creates a new block.
 * A new block creates a new paragraph element in markdown.
 *
 * To fix, you might do this
 * ```
 * {% layout %}{% card-badge %}Hello{% /card-badge %}{% card-badge %}Hello{% /card-badge %}{% /layout %}
 * ```
 *
 * While that's okay, it's not readable. So to workaround, we will say if a tag has a paragraph element as a child,
 * which has an inline component with another tag as a child, we'll remove the paragraph.
 *
 * @param node
 */
function handleTagWithinTag(currentNode: Node) {
  if (currentNode.type !== 'tag') {
    return;
  }

  if (currentNode?.children?.[0]?.type !== 'paragraph') {
    return;
  }

  if (currentNode?.children?.[0]?.children?.[0]?.type !== 'inline') {
    return;
  }

  if (
    currentNode?.children?.[0]?.children?.[0]?.children?.[0]?.type !== 'tag'
  ) {
    return;
  }

  const interior = currentNode.children[0].children[0];
  Object.assign(currentNode.children[0], interior);
}

function processAst(node: Node) {
  for (const currentNode of node.walk()) {
    handleTagWithinTag(currentNode);
  }
}

function transform(
  node: Node,
  { disableAllComponents, extraNodes, extraComponents }: MarkdownOptions
): RenderableTreeNode {
  const nodes: Partial<Record<NodeType, Schema>> = {};
  const tags: Record<string, Schema> = {};

  const allNodes = [...customNodes, ...(extraNodes || [])];
  const allComponents = [...customComponents, ...(extraComponents || [])];

  // The MarkdownDocument node  should always be run through the transformer
  // So, even if you disable all components, we still include the MarkdownDocument
  if (disableAllComponents) {
    nodes[doc.node.nodeType] = doc.node.schema;
  } else {
    for (const customNode of allNodes) {
      nodes[customNode.node.nodeType] = customNode.node.schema;
    }

    for (const customComponent of allComponents) {
      tags[customComponent.tag.name] = customComponent.tag.schema;
    }
  }

  processAst(node);

  return markdocTransform(node, { nodes, tags });
}

function transformMarkdown(
  markdown: string,
  opts: MarkdownOptions = {}
): RenderableTreeNode {
  const ast = parse(markdown, opts);
  return transform(ast, opts);
}

function reactRender(
  content: RenderableTreeNode,
  {
    disableAllComponents,
    testId,
    textColor,
    textVariant,
    textWeight,
    extraComponents,
    extraNodes,
  }: MarkdownOptions
): ReactNode {
  const components: Record<string, MarkdownReactComponent> = {};

  const allNodes = [...customNodes, ...(extraNodes || [])];
  const allComponents = [...customComponents, ...(extraComponents || [])];

  if (!disableAllComponents) {
    for (const customNode of allNodes) {
      if (customNode.component) {
        components[customNode.component.name] = customNode.component.value;
      }
    }

    for (const customComponent of allComponents) {
      components[customComponent.component.tagName] =
        customComponent.component.value;
    }
  }

  // Ensure the top-level node can set a test id
  if (Tag.isTag(content) && content.name === doc.component?.name) {
    content.attributes.testId = testId;
    content.attributes.textVariant = textVariant;
    content.attributes.textWeight = textWeight;
    content.attributes.textColor = textColor;
  }

  return markdocRenderers.react(content, React, { components });
}

function renderMarkdown(markdown: string, opts: MarkdownOptions): ReactNode {
  const ast = parse(markdown, opts);
  return renderAst(ast, opts);
}

function renderAst(ast: Node, opts: MarkdownOptions): ReactNode {
  const renderableTree = transform(ast, opts);
  return reactRender(renderableTree, opts);
}

interface BaseMarkdownProps {
  textColor?: MarkdownOptions['textColor'];
  textVariant?: MarkdownOptions['textVariant'];
  textWeight?: MarkdownOptions['textWeight'];
  disableAllComponents?: boolean | undefined;
}

type MarkdownProps =
  | {
      ast: Node;
      renderTreeNode?: never;
      markdown?: never;
      highlightHtml?: never;
    }
  | {
      ast?: never;
      renderTreeNode?: never;
      markdown: string;
      highlightHtml?: boolean | undefined;
    }
  | {
      ast?: never;
      renderTreeNode: RenderableTreeNode;
      markdown?: never;
      highlightHtml?: never;
    };

const Markdown: FC<MarkdownProps & BaseMarkdownProps & TestProps> = ({
  ast,
  renderTreeNode,
  markdown,
  highlightHtml,
  disableAllComponents,
  testId,
  textColor,
  textVariant,
  textWeight,
}) => {
  const { extraComponents, extraNodes } = useMarkdownContext();

  let result: ReactNode;
  if (ast) {
    result = renderAst(ast, {
      disableAllComponents,
      testId,
      textColor,
      textVariant,
      textWeight,
      extraComponents,
      extraNodes,
    });
  } else if (renderTreeNode) {
    result = reactRender(renderTreeNode, {
      disableAllComponents,
      testId,
      textColor,
      textVariant,
      textWeight,
      extraComponents,
      extraNodes,
    });
  } else {
    result = renderMarkdown(markdown as string, {
      highlightHtml,
      disableAllComponents,
      testId,
      textColor,
      textVariant,
      textWeight,
      extraComponents,
      extraNodes,
    });
  }

  return <>{result}</>;
};

export { Markdown, parse as parseMarkdown, transformMarkdown };
