import { getDeveloperApi } from '@squareup/dex-data-shared-developer-api';
import { CodeExampleLanguage } from '@squareup/dex-types-oas';
import { SpecificationVersionInfo } from '@squareup/dex-types-oas-path';
import { useEffect, useMemo, useState } from 'react';

import { useOasGraphLazy } from './graph-hooks';
import { getOasVersion, useOasLazy } from './oas-hooks';

let fernValue: typeof import('@fern-api/square-snippets') | null = null;

// define SnippetKey type with a endpointName and oasVersion properties
type SnippetKey = {
  sdkLanguage: string;
  endpointName: string;
  oasVersion: string;
};

type EndpointKey = {
  endpointName: string;
  sdkLanguage: string;
};

type FernLanguage =
  | 'typescript'
  | 'python'
  | 'go'
  | 'java'
  | 'csharp'
  | 'php'
  | 'ruby';

type DeveloperApi = ReturnType<typeof getDeveloperApi>;

// Keep this in module-level state so that all callers can share the snippet resolver
// It will also avoid re-parsing the snippet resolver
const fernClientMap = new Map<SnippetKey, unknown>();

// Additionally, cache at the endpoint level to improve load times
const fernEndpointMap = new Map<EndpointKey, unknown>();

// lazy-loads the fern square-snippets library
const useFernLibLazy = () => {
  const [fern, setFern] = useState<
    typeof import('@fern-api/square-snippets') | null
  >(fernValue);

  useEffect(() => {
    if (fern) {
      return;
    }

    async function load() {
      const fernLib = await import('@fern-api/square-snippets');
      fernValue = fernLib;
      setFern(fernLib);
    }

    load().catch(() => null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return fern;
};

/**
 * This is a helper hook that loads a valid fern snippet endpoint resolver object.
 * @param specOptions specOptions needed to fetch an OAS
 * @param devApi The developer api to make the fetch call
 * @returns A fern endpoint snippet resolver, used to generate code snippets
 */
const useFernLazy = (
  specOptions: SpecificationVersionInfo,
  endpointName: string,
  sdkLanguage: CodeExampleLanguage,
  devApi: DeveloperApi
) => {
  const { oas, ...oasResults } = useOasLazy(specOptions, devApi);

  const { graph } = useOasGraphLazy(specOptions, devApi);

  const fernLib = useFernLibLazy();

  const fern = useMemo(() => {
    if (!oas || !graph || !fernLib?.SnippetResolver) {
      return null;
    }

    let fernLanguage: FernLanguage = 'go';

    switch (sdkLanguage) {
      case 'go':
        fernLanguage = 'go';
        break;
      case 'javascript':
        fernLanguage = 'typescript';
        break;
      // these are not supported by the current square-snippets library version. update as available
      case 'java':
      case 'python':
      case 'ruby':
      case 'php':
      case 'csharp':
      default:
        return null;
    }

    const endpointMapKey = {
      endpointName,
      sdkLanguage,
    };
    if (fernEndpointMap.has(endpointMapKey)) {
      return fernEndpointMap.get(endpointMapKey);
    }

    // get cached fern clients, or create new ones
    const oasVersion = getOasVersion(specOptions);
    const mapKey = {
      sdkLanguage,
      endpointName,
      oasVersion,
    };
    if (!fernClientMap.has(mapKey)) {
      const oasObj = JSON.parse(oas);

      const snippetResolver = new fernLib.SnippetResolver({
        spec: {
          openapi: oasObj,
          type: 'openapi',
          overrides: oasObj['x-fern-overrides'],
        },
      }).sdk(fernLanguage);

      fernClientMap.set(mapKey, snippetResolver);
    }
    const fernClient = fernClientMap.get(mapKey);

    // get the endpoint and language specific code generator
    const endpoint = graph.getEndpoint(endpointName);
    if (!endpoint) {
      return null;
    }

    // TODO: get fern to export the type definition for this
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fernEndpointClient = (fernClient as any).endpoint(
      `${endpoint.httpMethod.toUpperCase()} ${endpoint.path}`
    );

    fernEndpointMap.set(endpointMapKey, fernEndpointClient);

    return fernEndpointClient;
  }, [oas, graph, fernLib, sdkLanguage, endpointName, specOptions]);

  return {
    ...oasResults,
    isLoading: oasResults.isLoading,
    fern,
  };
};

export { useFernLazy };
