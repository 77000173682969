import { DocPage } from '@squareup/dex-types-shared-docs';
import {
  Breadcrumb,
  BreadcrumbContainer,
  BreadcrumbDivier,
} from '@squareup/dex-ui';
import { Markdown } from '@squareup/dex-ui-markdown';
import { Display10 } from '@squareup/dex-ui-shared-base';
import { MarketPill, MarketPillVariant } from '@squareup/dex-ui-shared-market';
import React, {
  FC,
  Fragment,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';

import styles from './content.module.css';

interface DocPageHeadingBreadcrumbItem {
  href?: string | undefined;
  label?: string | undefined;
  title: string | ReactNode;
}

interface DocPageHeadingProps {
  heading: Partial<
    Pick<DocPage['heading'], 'summary' | 'title' | 'releaseStatus'>
  >;
  breadcrumbItems?: Array<DocPageHeadingBreadcrumbItem> | undefined;
  colorTheme?: 'teal' | 'blue' | 'pink' | 'orange' | 'purple' | undefined;
}

const DocPageHeading: FC<DocPageHeadingProps> = ({
  heading,
  breadcrumbItems = [],
  colorTheme,
}) => {
  const [isServer, setIsServer] = useState(true);
  useEffect(() => {
    setIsServer(false);
  }, []);

  const colorThemeToPillVariant = useMemo<MarketPillVariant>(() => {
    switch (colorTheme) {
      case 'teal':
        return 'success';
      case 'orange':
        return 'orange';
      case 'pink':
        return 'alpha';
      case 'purple':
        return 'insight';
      case 'blue':
      default:
        return 'emphasis';
    }
  }, [colorTheme]);

  const [releaseTitle, pillType] = useMemo<[string, MarketPillVariant]>(() => {
    switch (heading.releaseStatus) {
      case 'BETA':
        return ['Beta release', 'beta'];
      case 'DEPRECATED':
      case 'DEPRECATED and RESTRICTED':
        return ['Deprecated', 'critical'];
      case 'NEW':
        return ['New', 'insight'];
      case 'CAPABILITY':
        return ['API capability', colorThemeToPillVariant];
      case 'PUBLIC':
      default:
        return ['Public', 'emphasis'];
    }
  }, [heading.releaseStatus, colorThemeToPillVariant]);

  return (
    <>
      {breadcrumbItems && breadcrumbItems.length > 0 && (
        <BreadcrumbContainer
          testId={'doc-page-heading-breadcrumbs'}
          margin={{ bottom: '0.5x' }}
        >
          {breadcrumbItems.map((item, i) => {
            return (
              <Fragment key={typeof item.title === 'string' ? item.title : i}>
                <Breadcrumb
                  href={item.href}
                  trackingId={'doc-page-heading-breadcrumb-item'}
                  label={item.label}
                >
                  {item.title}
                </Breadcrumb>
                {i !== breadcrumbItems.length - 1 && <BreadcrumbDivier />}
              </Fragment>
            );
          })}
        </BreadcrumbContainer>
      )}
      {heading?.title && (
        <Display10
          testId="doc-page-heading"
          className={styles.heading}
          margin={{ bottom: '3x' }}
        >
          {heading.title}
          {heading?.releaseStatus &&
            heading.releaseStatus !== 'PUBLIC' &&
            !isServer && (
              <>
                &nbsp;
                <MarketPill variant={pillType}>{releaseTitle}</MarketPill>
              </>
            )}
        </Display10>
      )}
      {heading?.summary && <Markdown markdown={heading.summary} />}
    </>
  );
};

export { DocPageHeading };
