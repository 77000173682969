import { Config, Tag, Node } from '@markdoc/markdoc';
import { MarkdownNode } from '@squareup/dex-types-shared-markdown';
import {
  Paragraph,
  TextVariants,
  TextWeight,
  ColorVariants,
} from '@squareup/dex-ui-shared-base';
import clsx from 'clsx';
import jsonic from 'jsonic';
import React, { FC, PropsWithChildren } from 'react';

import styles from './nodes.module.css';

interface BaseFrontmatter {
  pageColorTheme?: 'teal' | 'blue' | 'pink' | 'orange' | 'purple' | undefined;
}

interface RequirementsFrontmatter extends BaseFrontmatter {
  pageType?: 'requirements' | undefined;
  requirementDomainId?: string | undefined;
}

interface LandingPageFrontmatter extends BaseFrontmatter {
  pageType?: 'landing' | undefined;
}

type MarkdownFrontmatter = LandingPageFrontmatter | RequirementsFrontmatter;

interface MarkdownDocumentProps {
  frontmatter: MarkdownFrontmatter | undefined;
  testId?: string | undefined;
  textVariant?: TextVariants['variant'] | undefined;
  textWeight?: TextWeight | undefined;
  textColor?: ColorVariants['colorVariant'] | undefined;
}

const MarkdownDocument: FC<PropsWithChildren<MarkdownDocumentProps>> = ({
  children,
  frontmatter = {},
  testId,
  textVariant = '30',
  textWeight,
  textColor,
}) => {
  return (
    <Paragraph
      data-testid={testId || 'markdown-doc'}
      variant={textVariant}
      weight={textWeight}
      colorVariant={textColor}
      {...(frontmatter.pageType && { 'data-page-type': frontmatter.pageType })}
      as="article"
      className={clsx(
        styles['article'],
        textVariant === '20' && styles['text-variant-20'],
        frontmatter.pageType && styles[frontmatter.pageType],
        frontmatter.pageColorTheme && styles[frontmatter.pageColorTheme]
      )}
    >
      {children}
    </Paragraph>
  );
};

const schema = {
  render: 'MarkdownDocument',
  children: ['inline'],
  attributes: {
    frontmatter: {
      type: Object,
      required: false,
    },
    testId: {
      type: String,
      required: false,
    },
    textVariant: {
      type: String,
      required: false,
      default: '30',
    },
    textWeight: {
      type: String,
      required: false,
    },
  },
  transform(node: Node, config: Config) {
    const attributes = node.transformAttributes(config);
    const children = node.transformChildren(config);

    // parse out the frontmatter, and make it a real object. We're using JSON
    if (attributes['frontmatter']) {
      try {
        // Use jsonic to allow slightly incorrect JSON
        const frontmatterAttrs = jsonic(attributes['frontmatter']);
        if (typeof frontmatterAttrs === 'object') {
          attributes['frontmatter'] = frontmatterAttrs;
        } else {
          attributes['frontmatter'] = {};
        }
      } catch {
        // pass
        attributes['frontmatter'] = {};
      }
    }

    return new Tag(this.render, attributes, children);
  },
};

const doc: MarkdownNode = {
  node: {
    nodeType: 'document',
    schema,
  },
  component: {
    name: schema.render,
    value: MarkdownDocument,
  },
};

export { doc, type MarkdownFrontmatter };
