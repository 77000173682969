import {
  BaseEndpointBuilder,
  endpointBuilder,
} from '@squareup/dex-data-shared-base-api';
import { ApplicationType } from '@squareup/dex-types-shared-application-type';
import { HTTP_METHOD } from '@squareup/dex-types-shared-http';
import { Location } from '@squareup/dex-types-shared-locations';
import { isDevelopmentEnvironment } from '@squareup/dex-utils-environment';

import { EXPLORER_GATEWAY_API_REDUCER_PATH } from './explorer-gateway-api-constants';

const sandboxModeHeaders = (applicationType: ApplicationType): HeadersInit => {
  const sandboxModeHeaderName = 'sandbox-mode';
  const sandboxModeHeaderValue =
    applicationType === ApplicationType.Sandbox ? 'true' : 'false';

  return {
    [sandboxModeHeaderName]: sandboxModeHeaderValue,
  };
};

/**
 * All endpoints for the connect api.
 */
const endpointsFactory = (
  builder: BaseEndpointBuilder<typeof EXPLORER_GATEWAY_API_REDUCER_PATH>
) => ({
  apiExplorerRequest: builder.query<
    {
      body: unknown;
      status: number | undefined;
      statsText: string | undefined;
      headers: Record<string, string> | undefined;
    },
    {
      applicationType: ApplicationType;
      accessToken: string;
      version: string;
      method: HTTP_METHOD;
      path: string;
      body?: Record<string, unknown> | undefined;
      formData?: FormData | undefined;
      // Writes a header to ensure this request is not seen in the API Logs
      skipApiLogsTracking?: boolean;
      authType?: 'bearer' | 'client';
      // Optional id to uniquely identify the request
      id?: string;
    }
  >({
    query: ({
      applicationType,
      accessToken,
      version,
      method,
      body,
      formData,
      path,
      skipApiLogsTracking,
      authType = 'bearer',
    }) => {
      const additionalHeaders: HeadersInit = {
        Authorization:
          authType === 'bearer'
            ? `Bearer ${accessToken}`
            : `Client ${accessToken}`,
        // API Logs will not track events marked with DeveloperPortal
        'X-Square-Property': skipApiLogsTracking
          ? 'DeveloperPortal'
          : 'ApiExplorer',
        'Square-Version': version,
        ...sandboxModeHeaders(applicationType),
        ...(formData ? {} : { 'Content-Type': 'application/json' }),
      };

      const newPath = isDevelopmentEnvironment()
        ? // In development, we do this so we can proxy all requests to explorer-gateway correctly
          `/api/explorer-gateway${path}`
        : path;

      return {
        path: newPath,
        method,
        additionalHeaders,
        body: formData || JSON.stringify(body),
        ignoreCredentials: true,
        skipInvalidResponseCode: true,
      };
    },
    transformResponse: (body: unknown, meta) => {
      const headers: Record<string, string> = {};

      // Oddly, the current TS version seems to think headers.entries doesn't exist
      // even though it does. This is a workaround for now since it's equivalent
      meta?.headers.forEach((value, key) => {
        headers[key] = value;
      });

      return {
        body,
        status: meta?.status,
        statsText: meta?.statusText,
        headers,
      };
    },
  }),
  getLocations: builder.query<
    { locations: Location[] },
    {
      accessToken: string | null | undefined;
      applicationType: ApplicationType;
    }
  >({
    query: ({ accessToken, applicationType }) => {
      const additionalHeaders: HeadersInit = {
        Authorization: `Bearer ${accessToken}`,
        'X-Square-Property': 'DeveloperPortal',
        ...sandboxModeHeaders(applicationType),
      };

      return {
        path: '/v2/locations',
        method: HTTP_METHOD.GET,
        additionalHeaders,
      };
    },
    transformResponse: (response: {
      locations: Location[];
      errors: unknown;
    }) => {
      const locations = response['locations'] ?? [];
      // Sort locations by created date
      locations.sort((a, b) =>
        Date.parse(b.created_at) > Date.parse(a.created_at) ? -1 : 1
      );
      // Set the location with the earliest created date as the default location
      if (locations[0]) {
        locations[0].default = true;
      }
      return {
        ...response,
        locations,
      };
    },
  }),
  resetLocationMCC: builder.mutation<
    unknown,
    { accessToken: string; locationId: string }
  >({
    query: ({ accessToken, locationId }) => {
      const additionalHeaders: HeadersInit = {
        Authorization: `Bearer ${accessToken}`,
        'X-Square-Property': 'DeveloperPortal',
        // This is a sandbox mode endpoint
        ...sandboxModeHeaders(ApplicationType.Sandbox),
      };

      return {
        path: `/v2/locations/${locationId}/mcc/reset`,
        method: HTTP_METHOD.POST,
        additionalHeaders,
      };
    },
  }),
});
const endpoints = endpointsFactory(endpointBuilder);

export { endpoints, endpointsFactory };
