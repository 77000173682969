import type { ExamplesObject } from '@square/ignition/dist/types/compiler';

interface PublishedOasSpec {
  content: string;
  version: string;
}

interface UnpublishedOasSpec {
  content: string;
  document_id: string;
}

type OasSpec = PublishedOasSpec | UnpublishedOasSpec;

interface ReferenceSpecification {
  type: string;
  content: OasSpec;
}

type SpecVersion =
  | PublishedOasSpec['version']
  | UnpublishedOasSpec['document_id'];

const codeExampleLanguages = [
  'curl',
  'ruby',
  'python',
  'csharp',
  'java',
  'php',
  'javascript',
  'go',
] as const;

type CodeExampleLanguage = typeof codeExampleLanguages[number];
type CodeExampleContent = Record<CodeExampleLanguage, ExamplesObject>;
interface CodeExamples {
  content: string;
  version: string;
}

type ReferenceCodeExamples = {
  type: 'examples';
  content: CodeExamples;
};

interface ReferenceExampleScenario {
  name: string;
  code: string;
  response: string;
}

export {
  type ReferenceCodeExamples,
  type CodeExampleLanguage,
  type CodeExamples,
  type CodeExampleContent,
  type SpecVersion,
  type ReferenceExampleScenario,
  type OasSpec,
  type PublishedOasSpec,
  type UnpublishedOasSpec,
  type ReferenceSpecification,
  codeExampleLanguages,
};
